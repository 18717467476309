/* .lang-toggle {

    position: "fixed";
    right: "33px";
    top: "30%";
    background-color: "transparent";
    border: "1px solid #fff";
    box-shadow: "0 0 2px #0A254C";

    width: "37px";
    height: "37px";
    text-shadow: "0 1px 2px #0A254C";
    border-radius: " 50%  ";
    z-index: 999;
    display: "flex";
    justify-content: "center";
    align-items: "center";
    color: "#fff";
    font-size: "10px";
    display: "flex";
    flex-direction: "column";
    justify-content: "center";
    align-items: "center";
} */

.room-bottom-icon span {
    display: flex;
    align-items: center;
}

.bg-fixed {
    background-attachment: fixed !important;
}

.sticky {
    z-index: 9999;
}

.fancybox__container {
    z-index: 9999999999999 !important;
}

.border-0 {
    border: 0 !important;
}

#main-header .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    width: 22px !important;
}

.breadcumb-area {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
    padding: 160px 0 160px !important;
    text-align: center !important;
    position: relative;
}

.booking-area {
    position: relative;
    margin-top: 60px;
}

@keyframes cruiseAnimation {
    0%, 100% {
        left: 7%;

    }

    50% {
        left: 90.5%;


    }
}

.booking-area::before {
    content: "";
    left: 7%;
    top: 0px;
    background-image: url(https://img.icons8.com/external-wanicon-lineal-wanicon/64/external-cruise-travel-wanicon-lineal-wanicon.png);
    background-size: contain;
    width: 40px;
    height: 40px;
    z-index: 999;
    position: absolute;
    background-repeat: no-repeat;
    filter: invert(1);
    animation: cruiseAnimation 120s ease-in-out infinite;
    transition-delay: 0ms;
    transition: all 0.2s smooth;
}

.breadcumb-area::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.1);
}

.breacumb-content {
    z-index: 1;
    position: relative;
}

.owl-dots {
    display: flex !important;
    justify-content: center;
    list-style: none;
}

.owl-dot {
    margin: 0 5px;
    cursor: pointer;
}

.owl-dot span {
    display: block;
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 50%;
}

.about-area .slick-active span, .rooms .slick-active .owl-dot span {
    background-color: #c19d68 !important;
}

.home-three .slick-next i {
    color: #B2B2B2;
    background: #fff;
    transition: 0.5s;
}

.slick-next i {
    color: #fff;
    display: inline-block;
    border: 1px solid #ffffff8a;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    position: relative;
    z-index: 1;
}

.slick-next::before {
    content: "\f138" !important;

}

.slick-prev::before {
    content: "\f12f" !important;
}

.slick-prev:before, .slick-next:before {
    font-size: 15px;
    display: inline-block;
    font-family: bootstrap-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 2;
    vertical-align: -0.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
    .slick-prev {
        right: 74px;
        bottom: 113px;
    }

    .slick-next {
        right: 13px;
        bottom: 113px;
    }
}



.slick-prev {
    position: absolute;
    right: 95px;
    bottom: 38px;
}

.home-three .slick-next {
    position: absolute;
    left: -40%;
    bottom: 40%;
    z-index: 9;
}

.slick-prev i {
    color: #ccc;
    display: inline-block;
    border: 1px solid #ffffff8a;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    position: relative;
    z-index: 99;
}

.slick-prev:before, .slick-next:before {
    color: #ccc;
}

.home-three .slick-prev {
    position: absolute;
    left: -48%;
    bottom: 40%;
    z-index: 9;
}

.home-three .owl-next, .home-three .owl-prev {
    position: relative;
}

.slick-prev:before, .slick-next:before {
    color: #abaaaa;
    opacity: 1;
    top: 2px;
    position: absolute;

}

.slick-prev:before {
    left: 5px;
}

.slick-next:before {
    left: 5px;
}

/* MobileMenu.css */
.mobile-menu-area .menu-toggle {
    display: none;
    /* Hide toggle button on larger screens */
    cursor: pointer;
}

@media (max-width: 767px) {
    .mobile-menu-area .menu-toggle {
        display: block;
        position: fixed;
        z-index: 999;
        /* Show toggle button on smaller screens */
    }

    .booking-area::before {
        display: none;
    }

    .booking-input-box:before {
        display: none !important;
        ;
    }

    .nav_scroll.open {
        display: block;

        /* Show menu when open */
    }

    .breadcumb-area {
        background-size: cover !important;
    }
}

#rooms .owl-next i {
    background: #D9B95E;
}

#rooms .slick-prev:before, #rooms .slick-next:before {
    color: #fff;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    color: #fff !important;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon, .css-1636szt, .css-bpeome-MuiSvgIcon-root-MuiSelect-icon, .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    color: #D9B95E !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #7c7b7b !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    background-color: #0A254C !important;
    color: #fff !important;
    box-shadow: 0px 2px 2px #fff !important;
}

.css-fvipm8 {
    border: 1px solid #7c7b7b !important;
    border-radius: 4px !important;
    outline: 0 !important;
}

.css-fvipm8.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    border-width: 0 !important;
}

.css-1ald77x {
    color: #D9B95E !important;
    background: #0A254C !important;
}

.css-1yhq19e {
    color: #D9B95E !important;
}

.css-i4bv87-MuiSvgIcon-root {
    color: #fff !important;

}

.css-qiwgdb.css-qiwgdb.css-qiwgdb, .css-p0rm37, .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    color: #fff !important;
    font-family: sans-serif
}



.react-datepicker-wrapper {
    text-align: left !important;
    display: flex !important;
    align-items: center;
    position: relative !important;
    bottom: 0 !important;
    right: 0 !important;
    top: 0px !important;
    left: 0 !important;
    margin: 0 !important;
    padding: 0 8px !important;
    /* pointer-events: none !important; */

    border-radius: inherit !important;
    border-style: solid !important;
    border-width: 1px !important;
    overflow: hidden !important;
    min-width: 0% !important;
    max-width: 93% !important;
    border-color: #7c7b7b !important;
    height: 54px !important;
    border-radius: 4px !important;
    cursor: pointer;
    z-index: 999;
    font-family: sans-serif;


}

.react-datepicker-wrapper::after {
    content: "\f073";
    font-family: 'Font Awesome 5 Free';

    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #D9B95E !important;
    opacity: 0.9;


}


.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    font-weight: 600;
    color: #0A254C !important;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
    border-color: #fff !important
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #D9B95E !important;
    color: #fff !important;
}

.react-datepicker__header, .react-datepicker__current-month, .react-datepicker__day-name {
    background-color: #D9B95E !important;
    color: #fff !important;

    font-weight: 900;
}

@media screen and (max-width: 768px) {
    #booking .row.add-bg {
        display: flex;
        flex-direction: column !important;
    }

    .css-193811q-MuiFormControl-root, .css-nrlmzz-MuiFormControl-root {
        width: 92% !important;


    }

    .booking-input-box {
        width: 100% !important;

    }

    .booking-button {
        text-align: center;
        top: unset !important;
        bottom: 4%;
        margin-left: 0 !important;
        padding-left: 0 !important;

    }
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
    opacity: 0.3;
    color: #ccc !important;
}

.react-datepicker__day--selected {
    color: #fff !important;
    opacity: 1 !important;
}

/* testimonial ,cabins */
#testimonial .slick-dots li button:before {
    color: #D9B95E;
    font-size: 12px;

    padding: 2px;
}

#rooms .slick-dots li button:before {
    color: #D9B95E;
    font-size: 15px;


    padding: 20px 0;
}

#cabin-details .slick-dots li button:before {
    color: #D9B95E;
    font-size: 11px;


    padding: 5px 0;
}

#cabin-details .slick-prev:before, #cabin-details .slick-next:before {
    opacity: 0 !important;
}

#cabin-details .slick-next {
    right: 40px;
    top: unset;
    bottom: 23%;
}

#cabin-details .slick-prev {
    right: 120px;
    left: unset;
    top: unset;
    bottom: 23%;
    z-index: 99;
}

#cabin-details .slick-next i, #cabin-details .slick-prev i {

    color: #fff;
    /* text-shadow: 0 0 1px #000;
    box-shadow: 0 0 2px #000; */
    display: inline-block;

    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    position: relative;
    z-index: 1;
    border: 1px solid #fff;
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.5s ease-in-out;
}

#cabin-details .slick-next:hover i, #cabin-details .slick-prev:hover i {
    background-color: #0A254C;
    border: 1px solid #0A254C;
}

#rooms .slick-dots .slick-active button:before, #cabin-details .slick-dots .slick-active button:before, #cabins .slick-dots .slick-active button:before {
    opacity: 1;
}

#cabins .slick-dots li button:before {
    color: #fff;
    text-shadow: 0 0 3px #2a2929;
    font-size: 10px;

    padding: 2px;

}

#cabins li.slick-active button:before {
    color: #D9B95E;
    text-shadow: unset;
    font-size: 14px;

}

#cabins .slick-dots {
    bottom: -50px;
}

/* Facilities */
.single-feature-box {
    position: relative;
    /* Other styles for .single-feature-box */
}

.single-feature-box.x:hover::before {
    width: 100%;
    /* Show the pseudo-element on hover */
}



.x {

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: width .5s ease;
    width: 0;
}

.single-feature-box:hover .x {
    width: 100%;
}

.f:before {
    background: url('https://cms.nilecapitalcruises.com/images/uploads/f1.jpg') no-repeat center center / cover;
}

.ff:before {
    background: url('https://cms.nilecapitalcruises.com/images/uploads/f2.jpg') no-repeat center center / cover;
}

.fff:before {
    background: url('https://cms.nilecapitalcruises.com/images/uploads/f3.jpg') no-repeat center center / cover;
}

.ffff:before {
    background: url('https://cms.nilecapitalcruises.com/images/uploads/f4.jpg') no-repeat center center / cover;
}

.fffff:before {
    background: url('https://cms.nilecapitalcruises.com/images/uploads/f5.jpg') no-repeat center center / cover;
}

.a:before {
    background: url('https://cms.nilecapitalcruises.com/images/uploads/f31.jpg') no-repeat center center / cover;
}

.aa:before {
    background: url('https://cms.nilecapitalcruises.com/images/uploads/f32.jpg') no-repeat center center / cover;
}

.aaa:before {
    background: url('https://cms.nilecapitalcruises.com/images/uploads/f33.jpg') no-repeat center center / cover;
}

.aaaa:before {
    background: url('https://cms.nilecapitalcruises.com/images/uploads/f34.jpg') no-repeat center center / cover;
}

.aaaaa:before {
    background: url('https://cms.nilecapitalcruises.com/images/uploads/f35.jpg') no-repeat center center / cover;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root, .css-fvipm8 {
    width: 100% !important;
}

@media screen and (min-width: 1101px) and (max-width: 1450px) {
    .header-menu ul li a {
        font-size: 14px;
    }

    .booking-input-box:before {
        display: none !important;
        ;
    }

    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root, .css-fvipm8 {
        width: 100% !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1110px) {
    .booking-input-box:before {
        display: none !important;
        ;
    }

    .header-menu ul li a {
        font-size: 13px;
    }

    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root, .css-fvipm8 {
        width: 100% !important;
    }

    .booking-button {
        right: -85% !important;
    }

    .booking-button button {
        max-width: 100px !important;

    }
}

/* faq  */
.question {
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 10px;
}

.answer {
    margin-bottom: 20px;
}

a.view-more {
    color: #D9B95E;
    text-decoration: underline;

}

a.view-more:hover {
    color: #0A254C;
}

.dark .inner.room-area, .dark .faqs-section {
    background-color: unset !important;
}

.dark .room-details-content h1, .dark .question {
    color: #fff !important;

}


.other-activities {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

#faqs .other-activities {
    display: block;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
    overflow: unset;
    text-overflow: unset;
}



/* booking page  */
.section-padding {
    padding-top: 100px;
    padding-bottom: 100px;
}

.section-bg {
    background-color: #f5f7fc;
}

.dark .section-bg {
    background-color: transparent;
}

.form-box {
    border: 1px solid rgba(128, 137, 150, .1);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 30px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, .1);
    -moz-box-shadow: 0 0 40px rgba(82, 85, 90, .1);
    box-shadow: 0 0 40px rgba(82, 85, 90, .1);
}

.form-title-wrap {
    padding: 20px;
    border-bottom: 1px solid rgba(128, 137, 150, .1);
}

.step-bar-list .step-bar {
    position: relative;
    z-index: 1;
}

.flex-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
}

.step-bar-list .step-bar.step-bar-active::after, .step-bar-list .step-bar.step-bar-active::before {
    background-color: #D9B95E;
}

.step-bar-list .step-bar::after, .step-bar-list .step-bar::before {
    display: block;
    position: absolute;
    background-color: #f5f7fc;
    height: 4px;
    content: '';
    width: 50%;
    left: 0;
    top: 25px;
    z-index: -1;
}

.step-bar-list .step-bar.step-bar-active .icon-element {
    text-indent: -9999px;
    position: relative;
    background-color: #D9B95E;
    border-color: #D9B95E;
}

.step-bar-list .step-bar .icon-element {
    text-indent: 0;
    background-color: #fff;
    color: #0d233e;
    -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, .1);
    -moz-box-shadow: 0 0 40px rgba(82, 85, 90, .1);
    box-shadow: 0 0 40px rgba(82, 85, 90, .1);
    border: 2px solid rgba(128, 137, 150, .2);
}

.icon-element {
    display: block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    background-color: #D9B95E;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    font-size: 25px;
    color: #fff;
}

.step-bar-list .step-bar.step-bar-active .icon-element::before {
    position: absolute;
    content: "\2713";
    font: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    color: #fff;
    font-size: 26px;
    text-indent: 0;
    font-weight: 900;
}

.step-bar-list .step-bar.step-bar-active::after, .step-bar-list .step-bar.step-bar-active::before {
    background-color: #D9B95E;
}

.step-bar-list .step-bar::after {
    left: auto;
    right: 0;
}




.card-item-list {

    display: flex;
}

.cruise--card {
    border: 1px solid rgba(128, 137, 150, .1);
}

.card-item {
    margin-bottom: 30px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, .1);
    -moz-box-shadow: 0 0 40px rgba(82, 85, 90, .1);
    box-shadow: 0 0 40px rgba(82, 85, 90, .1);
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    position: relative;
}

.card-item-list .card-img {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-border-radius: 5px 0 0 5px;
    -moz-border-radius: 5px 0 0 5px;
    border-radius: 5px 0 0 5px;
}

.card-item-list .card-img img {
    -webkit-border-radius: 5px 0 0 5px;
    -moz-border-radius: 5px 0 0 5px;
    border-radius: 5px 0 0 5px;
    object-fit: cover;
    height: 223px;

}

.card-item .card-img img {
    width: 100%;

    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.card-item .card-img {
    position: relative;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.card-item-list .card-body {
    -webkit-box-flex: 2;
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2;
}

.card-item .card-body {
    padding: 25px 20px 25px 30px;
    min-height: auto;
}

.card-item .card-title {
    font-size: 23px;
    color: #0d233e;
    margin-bottom: 5px;
}

.card-item .card-title a {
    color: #0d233e;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.card-item .card-title a:hover {
    color: #D9B95E;
}

.card-item .card-meta {
    font-size: 15px;
}

.card-item .card-rating {
    padding-top: 12px;
    padding-bottom: 12px;
}

.card-item .card-price {
    font-size: 15px;
}

.card-price.d-flex p {
    position: absolute;
    top: 10%;
    right: 3%;
}

.card-item .card-price .price__from {
    font-size: 13px;
}

.card-item .card-price .price__num {
    color: #D9B95E;
    font-size: 20px;
    font-weight: 600;
}

.card-btn {
    position: absolute;
    right: 2%;
    bottom: 13%;
}

.theme-btn-transparent {
    background-color: #fff;
    color: #D9B95E;
}

.theme-btn {
    font-size: 15px;
    color: #D9B95E;
    line-height: 45px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    position: relative;
    display: inline-block;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #D9B95E;
    font-weight: 500;
}

.theme-btn-transparent:hover {
    background-color: #D9B95E;
    color: #fff;
}

.theme-btn {
    font-size: 15px;
    color: #fff;
    line-height: 45px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #D9B95E;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    position: relative;
    display: inline-block;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #D9B95E;
    font-weight: 500;
}

.theme-btn:hover {
    background-color: #fff;
    color: #D9B95E;
}

#form-booking-page input[type=text] {
    background: #0A254C;
    border: 0;
    color: #fff;
}

#form-booking-page .react-datepicker-popper {
    z-index: 999;
}

#form-booking-page .booking-input-box:before {
    display: none;
}

.form-box {
    border: 1px solid rgba(128, 137, 150, .1);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 30px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, .1);
    -moz-box-shadow: 0 0 40px rgba(82, 85, 90, .1);
    box-shadow: 0 0 40px rgba(82, 85, 90, .1);
}

.form-content {
    padding: 20px;
}

.card-item .card-rating .badge {
    background-color: #D9B95E;
    font-size: 15px;
}

.text-white {
    color: #fff !important;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.card-item .card-rating .review__text {
    padding-left: 4px;
    padding-right: 4px;
    color: #D9B95E;
    font-size: 15px;
}

.card-item .card-rating .rating__text {
    font-size: 14px;
}

.section-block {
    height: 1px;
    background-color: #e2e1e1;
}

.list-items-flush li {
    border-bottom: 1px solid #e2e1e1;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.list-items li {

    color: #5d646d;
}

.list-items-2 li span {

    color: #0d233e;
    font-weight: 500;
}

.mb-n1, .my-n1 {
    margin-bottom: -0.25rem !important;
}

.la-ship:before {
    content: "\f21a";
}

.text-black {
    color: #0d233e !important;
}

.font-size-17 {
    font-size: 17px !important;
}

.la, .las {
    font-family: 'Line Awesome Free';
    font-weight: 900;
}

.la, .lab, .lad, .lal, .lar, .las {

    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.la, .las {
    font-family: 'Line Awesome Free';
    font-weight: 900;
}

#booking-form-data input[type=text] {

    border: 0 !important;
}

#booking-form-data .css-qiwgdb.css-qiwgdb.css-qiwgdb, #booking-form-data .css-p0rm37, #booking-form-data .css-qiwgdb.css-qiwgdb.css-qiwgdb, #booking-form-data .css-p0rm37, #booking-form-data .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    color: #2a2929 !important;
}

.dark .card-item .card-rating {
    color: #333;
}

.room-details-amenities {
    box-shadow: 0 0 5px #ccc;
    padding: 30px 30px 20px;
    border-radius: 3px;

}

.form-check-input.is-valid, .was-validated .form-check-input:valid {
    border-color: #0d233e67 !important;
    margin-right: 8px !important;
}

.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {

    color: #0A254C !important;
}

.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
    background-color: #D9B95E !important;
    border: 0 !important;
}

.facilities-area .row:nth-child(2n).add-boder {
    display: flex !important;
    flex-direction: row-reverse !important;
}



#form-booking-page.group input[type=text] {
    position: absolute;

    color: #fff;
}

header .header-button:before {
    display: none;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #d9b95e !important;
}

.form-check-input:checked {
    background-color: #D9B95E;
    border-color: #D9B95E;
}

.form-label {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    color: rgba(0, 0, 0, 0.6) !important;
}

/* .checkbox-label label {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    color: rgba(0, 0, 0, 1) !important;
    text-decoration: underline;
} */

.css-10hyoxo-MuiStack-root, .css-ahj2mt-MuiTypography-root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    color: rgba(0, 0, 0, 0.6) !important;
}

.css-vubbuv {
    color: #fff;
}