.fb-gray-bg {
    background-color: #f5f5f5;
}

.fb-block-header {
    background-color: #D9B95E;
    font-size: 13pt;
    margin-top: 25px;
    padding: 10px;
}

.fb-dark-bg {
    background-color: #0A254C;
    color: #f5f5f5;
}

.dark .fb-dark-bg {
    background-color: #D9B95E;
    color: #f5f5f5;
}

div.fb-results-accommodation>div.fb-results-acc-photo {
    padding: 0;
}

.fb-results-acc-photo.fb-large-layer-room, .fb-carousel-image.fb-large-layer-room {
    height: 370px;
}

div.fb-results-accommodation>div.fb-results-acc-selects {
    border-left: 1px solid #C4C4C4;
    border-right: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
    padding: 10px;
}

@media screen and (min-width: 600px) {
    .fb-results-room-details-mobile-link {
        font-size: 24px;
        line-height: 28px;
    }

    #cabin-details.card-area .room-amenities-item ul li {
        font-size: 13px !important;
    }

    #cabin-details.card-area .room-content a {
        font-size: 35px !important;
        font-family: unset !important;
        font-size: 28px !important;
        font-family: unset !important;
        box-shadow: 0 3px 2px #f5f5f5;

        /* border-radius: 3px; */
        color: #D9B95E !important;

        padding: 5px 15px;
    }
}

#cabin-details.card-area .room-content h4 {
    text-transform: none !important;
}

.fb-results-room-details-mobile-link {
    float: right;
    color: #fff;
    font-size: 18px;
    line-height: 24px;
    padding: 10px;
    margin: -10px;
}

#cabin-details.card-area .slick-dots {
    bottom: 20px;
}

#cabin-details.card-area .slick-dots li button:before {
    color: #fff !important;
    font-size: 15px !important;
    text-shadow: 0 0 3px #ccc !important;


}

.offcanvas.show:not(.hiding), .offcanvas.showing {
    width: 100% !important;
}

.accordion-item {
    border: 0;
    padding: 0;
}

.accordion-item button {
    padding: 0;
}

.accordion-button:not(.collapsed) {
    background-color: transparent;
    color: #0A254C;
    outline: none;
    box-shadow: 0 0 2px #D9B95E;
    border: 1px solid #D9B95E;
    padding-left: 5px;
    padding-right: 5px;
}